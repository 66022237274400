import {
  Banner,
  BannerDescription,
  BannerDisplay,
  BannerFootnote,
  BannerImage,
  BannerImageContainer,
  BannerLinks,
  BannerSecondaryImage,
  BannerSecondaryImageContainer,
  BannerSubtitle,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  Link,
  P,
  PipeSeparator,
  PromoCode,
} from "@vp/swan";
import { marked } from "marked";
import React, { useEffect, useRef, useState } from "react";
import { EDITORIAL_FONT, HOLIDAY_FONT, PLTC_MARKETING_COLORS } from "../../constants";
import {
  DefaultBannerWrapperProps,
  IHolidayFont,
  IImageLink,
  VideoSrcProps,
} from "../../types/banner";
import {
  getImageProps,
  getIsRightAligned,
  getLinkIdentifier,
  getTextProps,
  headingResolver,
  resolveAriaLabel,
  validateBannerProps,
} from "../../utils";
import { handlePlayPauseVisibility } from "../../utils/playPauseButtonVisibility";
import { BannerLogo } from "../BannerLogo";
import { BannerPriceDisplay } from "../BannerPriceDisplay";
import { Eyebrow } from "../Eyebrow";
import { Image } from "../Image";
import PlayPauseButton from "../Video/PlayPauseButton";
import Video from "../Video/Video";
import { CTAButton } from "./CTAButton";

const DefaultBannerWrapper: React.FunctionComponent<DefaultBannerWrapperProps> = (props) => {
  const {
    textContent,
    bannerImage = [],
    layout,
    ctaButton = [],
    render,
    extraProps = {},
    trackingHandler,
    locale,
    isDoubleBanner,
    isFirstBanner = false,
    firstBannerCTAButtons,
    gmpvFlowComponent,
  } = props;
  const {
    eyebrow = "",
    discount = [],
    title = "",
    subtitle = [],
    description = "",
    price,
    footnote = "",
    holidayFont = HOLIDAY_FONT.NONE,
    hideVatMessageForPrice,
    hideShippingMessageInVatMessageForPrice,
    promocode = "",
    offerEnding = "",
    eyebrowIcon,
    bannerLogo,
    editorial = EDITORIAL_FONT.NO,
  } = textContent ?? {};
  const { imageOverrideProps = {}, useH1ForTitle = false } = extraProps;
  const getElementAs = headingResolver()(useH1ForTitle && (!isDoubleBanner || isFirstBanner));
  const {
    imageLink = {},
    imageSrc = [],
    videoSrc = {} as VideoSrcProps,
    altOverride = "",
    ariaLabel,
    ariaLabelForVideo,
  } = bannerImage[0] || {};
  const {
    imageLink: secondaryImageLink = {},
    imageSrc: secondaryImageSrc = [],
    altOverride: secondaryImageAltOverride = "",
    ariaLabel: secondaryImageAriaLabel,
  } = bannerImage[1] ?? {};
  const idProps = { ...(props?.id ? { id: props.id } : {}) };
  const [error, setError] = useState(false);
  const { twoImages, textBoxHorizontalAlign, variant } = layout || {};
  const filteredCTAButtons = ctaButton?.filter(
    (button) => button.label || button.gmpvUploadButton?.mpvId,
  );
  const {
    openInNewTab = false,
    bgc = "",
    imageFocalPoint: layoutImageFocalPoint,
    ...restLayout
  } = layout || {};
  const hexValue = layout?.bgc?.startsWith("#")
    ? layout?.bgc
    : PLTC_MARKETING_COLORS[layout?.bgc as keyof typeof PLTC_MARKETING_COLORS];
  const primaryImageProps = getImageProps(imageSrc, altOverride);
  const secondaryimageProps = getImageProps(secondaryImageSrc, secondaryImageAltOverride);
  const processedDiscount = getTextProps(discount[0]);
  const processedTitle = getTextProps(title);
  const processedSubtitle = getTextProps(subtitle[0]);
  const processedDescription = getTextProps(description);
  const processedOfferEnding = getTextProps(offerEnding);
  const processedPromocode = getTextProps(promocode);
  const processedFootnote = getTextProps(footnote);
  const videoRef = useRef<HTMLVideoElement>(null);
  const layoutProps = {
    ...restLayout,
    bgc,
  };
  const [showPlayPause, setShowPlayPause] = useState(false);

  const handleTracking = (event: React.MouseEvent, imageLink: IImageLink, imageId: number) => {
    if (trackingHandler) {
      const { href, actionId, trackingId, linkType } = imageLink;
      const linkIdentifier = getLinkIdentifier(linkType, actionId, trackingId);
      trackingHandler(event, {
        destinationUrl: href,
        linkIdentifier: linkIdentifier as string,
        ctaPosition: twoImages ? imageId : 0,
        openInNewTab,
      });
    }
  };

  useEffect(() => {
    const validationMessage = validateBannerProps({ firstBannerProps: props }, false);
    if (!validationMessage?.isValid) {
      console.error("Banner validation error: " + validationMessage?.messages?.join(". "));
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.oncanplaythrough = function () {
        videoRef.current!.muted = true;
        videoRef.current!.play();
      };
    }
  }, []);

  handlePlayPauseVisibility(videoRef, videoSrc, setShowPlayPause);

  return error ? null : (
    <Banner
      {...layoutProps}
      render={render}
      data-testid='default-wrapper'
      {...idProps}
      style={{ "--swan-public-marketing-background": hexValue } as React.CSSProperties}
      {...(editorial === EDITORIAL_FONT.YES ? { editorial: true } : {})}
    >
      <BannerTextContainer>
        <BannerText>
          {!!bannerLogo?.imageSrc && (
            <BannerLogo
              bannerLogo={bannerLogo}
              imageOverrideProps={imageOverrideProps}
              isDoubleBanner={isDoubleBanner}
              openInNewTab={openInNewTab}
              trackingHandler={trackingHandler}
            />
          )}
          {(!!eyebrow || !!eyebrowIcon) && (
            <Eyebrow
              eyebrowText={eyebrow}
              holidayFont={holidayFont as IHolidayFont}
              icon={eyebrowIcon}
              imageOverrideProps={imageOverrideProps}
              textBoxHorizontalAlign={textBoxHorizontalAlign}
              variant={variant}
              twoImages={!!twoImages}
            />
          )}
          {discount?.length > 0 && processedDiscount && (
            <BannerDisplay
              as={getElementAs("div")}
              fontSkin={holidayFont === HOLIDAY_FONT.HEADING ? "editorial-headline" : undefined}
              dangerouslySetInnerHTML={{
                __html: marked.parseInline(processedDiscount, { async: false }) as string,
              }}
            />
          )}
          {!!processedTitle && (
            <BannerTitle
              as={getElementAs("p")}
              fontSkin={holidayFont === HOLIDAY_FONT.HEADING ? "editorial-headline" : undefined}
            >
              {processedTitle}
            </BannerTitle>
          )}
          {subtitle?.length > 0 && processedSubtitle && (
            <BannerSubtitle
              as={getElementAs("p")}
              fontSkin={holidayFont === HOLIDAY_FONT.SUBTITLE ? "editorial-content" : undefined}
            >
              {processedSubtitle}
            </BannerSubtitle>
          )}
          {!!processedDescription && (
            <BannerDescription as='div'>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parseInline(processedDescription, { async: false }) as string,
                }}
              />
            </BannerDescription>
          )}
          {(!!processedOfferEnding || !!processedPromocode) && (
            <BannerDescription>
              <P>
                <PromoCode as='span' style={{ textTransform: "none" }}>
                  {processedPromocode}
                </PromoCode>
                {!!offerEnding && !!promocode && <PipeSeparator />}
                {processedOfferEnding}
              </P>
            </BannerDescription>
          )}
          {!!price && !!locale && (
            <BannerPriceDisplay
              price={price}
              locale={locale}
              hideShippingMessageInVatMessageForPrice={hideShippingMessageInVatMessageForPrice}
              hideVatMessageForPrice={hideVatMessageForPrice}
            />
          )}
          {filteredCTAButtons?.length > 0 && (
            <BannerLinks>
              {ctaButton?.map((button, index) => (
                <CTAButton
                  {...button}
                  key={button.label + button?.type}
                  trackingHandler={trackingHandler}
                  ctaPosition={index + 1}
                  openInNewTab={openInNewTab}
                  isDoubleBanner={isDoubleBanner}
                  firstBannerCTAButtons={firstBannerCTAButtons}
                  gmpvFlowComponent={gmpvFlowComponent}
                />
              ))}
            </BannerLinks>
          )}
          {!!processedFootnote && (
            <BannerFootnote as='div'>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked.parseInline(processedFootnote, { async: false }) as string,
                }}
              />
            </BannerFootnote>
          )}
        </BannerText>
      </BannerTextContainer>

      <BannerImageContainer>
        <BannerImage imageFocalPoint={primaryImageProps.imageFocalPoint ?? layoutImageFocalPoint}>
          {videoSrc?.video?.length ? (
            <Video
              videoSrc={videoSrc}
              videoRef={videoRef}
              fallbackImage={primaryImageProps}
              imageOverrideProps={imageOverrideProps}
              ariaLabelForVideo={ariaLabelForVideo}
            />
          ) : imageLink?.href ? (
            <Link
              href={imageLink?.href}
              onClick={(event) => handleTracking(event, imageLink, 2)}
              data-testid='banner-image-with-link'
              target={openInNewTab ? "_blank" : "_self"}
              aria-label={resolveAriaLabel(ariaLabel)}
            >
              <Image
                imageSrc={primaryImageProps?.src || []}
                altOverride={primaryImageProps?.altTextOverride}
                imageOverrideProps={imageOverrideProps}
              />
            </Link>
          ) : (
            <Image
              imageSrc={primaryImageProps?.src || []}
              altOverride={primaryImageProps?.altTextOverride}
              imageOverrideProps={imageOverrideProps}
            />
          )}
        </BannerImage>
        {showPlayPause && (
          <div
            style={{
              position: "absolute",
              bottom: "16px",
              left: getIsRightAligned(layout) ? "unset" : "16px",
              right: getIsRightAligned(layout) ? "16px" : "unset",
            }}
          >
            <PlayPauseButton videoRef={videoRef} />
          </div>
        )}
      </BannerImageContainer>

      {!!twoImages && (
        <BannerSecondaryImageContainer>
          <BannerSecondaryImage
            imageFocalPoint={secondaryimageProps?.imageFocalPoint ?? layoutImageFocalPoint}
          >
            {bannerImage[1]?.imageLink?.href ? (
              <Link
                href={secondaryImageLink?.href}
                onClick={(event) => handleTracking(event, secondaryImageLink, 1)}
                data-testid='banner-secondary-image-with-link'
                target={openInNewTab ? "_blank" : "_self"}
                aria-label={getTextProps(secondaryImageAriaLabel ?? "")}
              >
                <Image
                  imageSrc={secondaryimageProps?.src || []}
                  altOverride={secondaryimageProps?.altTextOverride}
                  imageOverrideProps={imageOverrideProps}
                />
              </Link>
            ) : (
              <Image
                imageSrc={secondaryimageProps?.src || []}
                altOverride={secondaryimageProps?.altTextOverride}
                imageOverrideProps={imageOverrideProps}
                testId="banner-secondary-image'"
              />
            )}
          </BannerSecondaryImage>
        </BannerSecondaryImageContainer>
      )}
    </Banner>
  );
};

export { DefaultBannerWrapper };
