/* eslint-disable no-unused-vars */
import { BannerProps, CoreProps } from "@vp/swan";

export type CTAButtonProps = {
  skin?: "primary" | "secondary";
  label?: string | TextProps;
  type: "button" | "link" | "ctaLink";
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
  ctaPosition?: number;
  href?: { href?: string; ctaPosition?: number; actionId?: string; trackingId?: string, linkType?: string };
  openInNewTab?: boolean;
  totalButtons?: number;
  isDoubleBanner?: boolean;
  firstBannerCTAButtons?: number; // to be passed only in case of 2nd banner in double banner
  gmpvUploadButton?: { mpvId?: string }
  gmpvFlowComponent?: JSX.Element
};

export type TextProps = {
  key?: string;
  value?: string;
}

export type ImageSrcProps = {
  src: string;
  breakpoint: number;
};

export type VideoSrcProps = {
  video: {
    src: string;
    breakpoint: number;
    poster: string;
  }[];
};

export type IBannerLink = {
  href?: string;
  actionId?: string;
  trackingId?: string;
  linkType?: string;
};

export type IImageLink = {
  href?: string;
  actionId?: string;
  trackingId?: string;
  linkType?: string;
  text?: string;
};

export type ITrackingProps = {
  destinationUrl?: string;
  linkIdentifier: string;
  ctaPosition: number | string;
  openInNewTab?: boolean;
  mpvId?: string
};

export type IBannerLayoutProps = {
  openInNewTab?: boolean;
  bgc?: any;
};

export type IPrice = {
  mpvId: string;
};

export type CloudinaryImageSrcProps = {
  imageFocalPoint?: ImageFocalPoint;
  cloudinaryImage: ImageSrcProps[];
  altTextOverride?: string;
}

export type ImageProps = {
  videoSrc?: VideoSrcProps,
  imageSrc?: ImageSrcProps[] | CloudinaryImageSrcProps;
  imageLink?: IImageLink;
  altOverride?: string;
  ariaLabel?: string | TextProps;
  ariaLabelForVideo?: string;
};

export type ImagePropsWithoutLink = {
  imageSrc?: ImageSrcProps[] | CloudinaryImageSrcProps;
  altOverride?: string;
  ariaLabel?: string | TextProps;
};

export type IHolidayFont = "eyebrow" | "heading" | "none";
export type IEditorialFont = "yes" | "no";

export type DefaultBannerWrapperProps = {
  id?: string;
  bannerImage: ImageProps[];
  bannerLink?: IBannerLink;
  ctaButton: CTAButtonProps[];
  textContent: {
    offerEnding?: string | TextProps;
    promocode?: string | TextProps;
    title?: string | TextProps;
    subtitle?: string[] | TextProps[];
    description?: string | TextProps;
    discount?: string[] | TextProps[];
    eyebrow?: string | TextProps;
    footnote?: string | TextProps;
    price?: IPrice;
    holidayFont?: IHolidayFont;
    hideVatMessageForPrice?: boolean;
    hideShippingMessageInVatMessageForPrice?: boolean;
    eyebrowIcon?: ImagePropsWithoutLink;
    bannerLogo?: ImageProps;
    ariaLabel?: string | TextProps;
    editorial?: IEditorialFont;
  };
  layout: Omit<BannerProps, "bgc"> & IBannerLayoutProps;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  render?: (prop: any) => React.ReactElement;
  hideBannerBelowMedium?: "left" | "right" | "none";
  extraProps?: {
    imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
    useH1ForTitle?: boolean;
  };
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
  locale?: string;
  isDoubleBanner?: boolean;
  isFirstBanner?: boolean; // adding this to determine h1 tag from first banner in case of double banner
  firstBannerCTAButtons?: number; // to be passed only in case of 2nd banner in double banner
  gmpvFlowComponent?: JSX.Element
};

export type DefaultContentfulTextContentProps = {
  offerEnding?: string | TextProps;
  promocode?: string | TextProps;
  title?: string | TextProps;
  subtitle?: string[] | string | TextProps | TextProps[];
  description?: string | TextProps;
  discount?: string[] | string | TextProps | TextProps[];
  eyebrow?: string | TextProps;
  footnote?: string | TextProps;
  price?: IPrice;
  holidayFont?: IHolidayFont;
  hideVatMessageForPrice?: boolean;
  hideShippingMessageInVatMessageForPrice?: boolean;
  eyebrowIcon?: ImagePropsWithoutLink;
  bannerLogo?: ImageProps;
  ariaLabel?: string | TextProps;
  editorial?: IEditorialFont;
};

export type DefaultContentfulBannerWrapperProps = {
  id?: string;
  bannerImage: ImageProps[] | ImageProps;
  bannerLink?: IBannerLink;
  ctaButton: CTAButtonProps[] | CTAButtonProps;
  textContent: DefaultContentfulTextContentProps;
  layout: Omit<BannerProps, "bgc"> & IBannerLayoutProps;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  render?: (prop: any) => React.ReactElement;
  hideBannerBelowMedium?: "left" | "right" | "none";
  extraProps?: {
    imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
    useH1ForTitle?: boolean;
  };
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
  locale?: string;
  isDoubleBanner?: boolean;
  isFirstBanner?: boolean;
  firstBannerCTAButtons?: number; // to be passed only in case of 2nd banner in double banner
  gmpvFlowComponent?: JSX.Element
};

export type BannerWrapperProps = {
  id?: string;
  bannerProps: DefaultBannerWrapperProps[];
  debug?: boolean;
  extraProps?: {
    imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
    useH1ForTitle?: boolean;
  };
  leftNav?: JSX.Element;
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
  locale?: string;
  gmpvFlowComponent?: JSX.Element
};

export type ContentfulBannerWrapperProps = {
  id?: string;
  bannerProps: DefaultContentfulBannerWrapperProps[];
  debug?: boolean;
  extraProps?: {
    imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
    useH1ForTitle?: boolean;
  };
  leftNav?: JSX.Element;
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
  locale?: string;
  gmpvFlowComponent?: JSX.Element
};

export type DoubleBannerWrapperProps = {
  id?: string;
  firstBannerProps: DefaultBannerWrapperProps;
  secondBannerProps: DefaultBannerWrapperProps;
  hideBannerBelowMedium?: "left" | "right" | "none";
  debug?: boolean;
  extraProps?: {
    imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
    useH1ForTitle?: boolean;
  };
  trackingHandler?: (event: React.MouseEvent, props: ITrackingProps) => void;
  locale?: string;
};

export type LeftNavBannerWrapperProps = DefaultBannerWrapperProps & {
  leftNav?: JSX.Element;
};

export type ValidationStatus = {
  message: string[];
  isValid: boolean;
};

export type IEyebrow = {
  eyebrowText?: string | TextProps;
  icon?: ImagePropsWithoutLink;
  holidayFont?: IHolidayFont;
  imageOverrideProps?: Omit<CoreProps<JSX.IntrinsicElements["img"]>, "alt">;
  textBoxHorizontalAlign?: "center" | "left" | "right" | null;
  variant?: "card" | "full-width-image" | null;
  twoImages?: boolean;
};

export enum Locale {
  deDE = "de-de",
  esES = "es-es",
  fiFI = "fi-fi",
  nlNL = "nl-nl",
  itIT = "it-it",
  svSE = "sv-se",
  nbNO = "nb-no",
  ptPT = "pt-pt",
  daDK = "da-dk",
  frCA = "fr-ca",
  esUS = "es-us",
  enCA = "en-ca",
  nlBE = "nl-be",
  enIE = "en-ie",
  frBE = "fr-be",
  frFR = "fr-fr",
  frCH = "fr-ch",
  deAT = "de-at",
  deCH = "de-ch",
  itCH = "it-ch",
  enAU = "en-au",
  enNZ = "en-nz",
  enSG = "en-sg",
  enIN = "en-in",
}

export type ImageFocalPoint = "left top" | "left center" | "left bottom" | "center top" | "center center" | "center bottom" | "right top" | "right center" | "right bottom" | null | undefined