/* eslint-disable @typescript-eslint/no-explicit-any */
import { BannerProps } from "@vp/swan";
import {
  CloudinaryImageSrcProps,
  DefaultBannerWrapperProps,
  DefaultContentfulBannerWrapperProps,
  IBannerLayoutProps,
  ImageSrcProps,
  TextProps,
} from "../types";

const allowedKeys = ["textContent", "bannerImage", "ctaButton"];
export const getProcessedProps = (
  obj: DefaultContentfulBannerWrapperProps,
): DefaultBannerWrapperProps => {
  let processedObj: DefaultBannerWrapperProps | object = {};

  Object.keys(obj).forEach((key) => {
    if (allowedKeys.indexOf(key) !== -1) {
      if (key === "textContent") {
        const processedDiscount = Array.isArray(obj.textContent?.discount)
          ? obj.textContent?.discount
          : [obj.textContent?.discount];
        const processedSubtitle = Array.isArray(obj.textContent?.subtitle)
          ? obj.textContent?.subtitle
          : [obj.textContent?.subtitle];
        const processedTextContent = {
          ...obj.textContent,
          discount: obj.textContent?.discount ? processedDiscount : [],
          subtitle: obj.textContent?.subtitle ? processedSubtitle : [],
        };
        (processedObj as any)["textContent"] = processedTextContent;
      } else if (key === "bannerImage" && obj.bannerImage) {
        (processedObj as any)[key] = Array.isArray(obj.bannerImage)
          ? obj.bannerImage.map((image) => ({
            ...image,
            ariaLabel: image.ariaLabel
              ? image.ariaLabel
              : image.imageLink?.text,
          }))
          : [
            {
              ...obj.bannerImage,
              ariaLabel: obj.bannerImage.ariaLabel
                ? obj.bannerImage.ariaLabel
                : obj.bannerImage.imageLink?.text,
            },
          ];
      } else {
        (processedObj as any)[key] = Array.isArray(obj[key as keyof DefaultBannerWrapperProps])
          ? obj[key as keyof DefaultBannerWrapperProps]
          : [obj[key as keyof DefaultBannerWrapperProps]];
      }
    } else {
      (processedObj as any)[key] = obj[key as keyof DefaultBannerWrapperProps];
    }
  });

  if (!(processedObj as DefaultBannerWrapperProps)?.layout?.variant) {
    processedObj = {
      ...processedObj,
      layout: {
        ...(processedObj as DefaultBannerWrapperProps)?.layout,
        variant: "full-width-image",
      },
    };
  }
  return processedObj as DefaultBannerWrapperProps;
};

export const getProcessedImageSourceSet = (srcArray: ImageSrcProps[]) => {
  const processedSrcArray: string[] = [];
  srcArray.forEach(({ src, breakpoint }) => {
    if (src) {
      processedSrcArray?.push(src + " " + breakpoint + "w");
    }
  });
  return processedSrcArray?.length > 0 ? processedSrcArray.join(", ") : "";
};

export const getImageProps = (
  imageProps?: ImageSrcProps[] | CloudinaryImageSrcProps,
  altText?: string,
) => {
  if (imageProps && "cloudinaryImage" in imageProps) {
    return { src: imageProps.cloudinaryImage, altTextOverride: imageProps?.altTextOverride, imageFocalPoint: imageProps?.imageFocalPoint };
  }
  return { src: imageProps, altTextOverride: altText };
};

export const getTextProps = (text: string | TextProps) => {
  if (typeof text === "string") {
    return text;
  }
  return text?.value;
};

export const getTargetForLink = (href: string | undefined): "_blank" | "_self" => {
  if (!href) {
    return "_self";
  }
  try {
    const url = new URL(href, window.location.origin);
    const domain = url.hostname.toLowerCase();

    const isVistaprintDomain = domain.includes("vistaprint.");
    return isVistaprintDomain ? "_self" : "_blank";
  } catch (e) {
    return "_self";
  }
};

export const headingResolver = () => {
  let h1Assigned = false;

  return (useH1ForTitle: boolean) => (defaultType: "div" | "p") => {
    if (useH1ForTitle && !h1Assigned) {
      h1Assigned = true;
      return "h1";
    }
    return defaultType;
  };
};

export const getLinkIdentifier = (linkType: string = "", actionId: string = "", trackingId: string = "") => {
  if (linkType === "External") {
    return trackingId;
  }
  if (actionId) {
    return actionId;
  }
  return trackingId;
};

export const getIsRightAligned = (layout: Omit<BannerProps, "bgc"> & IBannerLayoutProps) => {
  return layout?.variant === "full-width-image" && layout?.textBoxHorizontalAlign === "left";
};

export const resolveAriaLabel = (label?: string | TextProps): string | undefined => {
  return typeof label === "string" ? label : label ? getTextProps(label) : undefined;
};